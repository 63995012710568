<template>
  <div>
    <div class="index1">
      <div
        class="page-header clear-filter"
        filter-color="orange"
      >
        <parallax
          class="page-header-image"
          style="background-image:url('img/img/header.jpg')"
        />
        <div class="container-fluid">
          <div class="col-md-4">
            <div class="hero-images-container-1">
              <img
                src="img/img/logo.png"
                alt=""
              >
            </div>
          </div>
          <div class="col-md-8">
            <div class="container">
              <h1 class="h1-seo">
                Sustainable Environmental Urban Solutions
              </h1>
              <h3>SEUS Consult (Z) Ltd</h3>
              <h4 class="uppercase">
                Integrated Environmental & Project Management Consultants
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="index">
      <!-- Title Section -->
      <div class="title-section">
        <div class="row-paged">
          <div class="col-md-4 logo">
            <img
              src="img/img/logo.png"
              alt=""
            >
          </div>
          <div class="col-8 pull-right">
            <div class="title-text">
              <h4 class="h2-seo">
                Sustainable Environmental Urban Solutions
              </h4>
              <h5>SEUS Consult (Z) Ltd</h5>
              <h7 class="uppercase">
                Integrated Environmental & Project Management Consultants
              </h7>
            </div>
          </div>
        </div>
      </div>

      <!-- Banner -->
      <div class="banner" />

      <!-- Image Grid -->
      <div class="image-grid">
        <div
          v-for="(image, index) in images"
          :key="index"
          class="grid-item"
          :style="{ backgroundImage: `url(${image})` }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Parallax } from '@/components';
export default {
  name: "Index",
  bodyClass: 'index-page',
  components: {
    Parallax,

  },
  data() {
    return {
      images: [
        "img/projects/IMG-20241022-WA0015.jpg",
        "img/projects/IMG-20241022-WA0009.jpg",
        "img/Andrew_2024.jpg",
        "img/Image-2024-11-10.jpg",

      ],
    };
  },
};
</script>

<style scoped>
/* Title Section */
.title-section {
  display: block;
  align-items: center;
  background-color: #dcedc8; /* Light green background */
  /*padding-top: 0.5rem;*/
  text-align: center;
  /*margin-top: 65px;*/
  margin-left: auto;
  margin-right: auto;
}
.logo img {
  max-width: 100%; /* Ensures the image scales properly within the column */
  max-height: 100%;
  padding-top: 1rem;
}

.logo {

  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  height: 100%; /* Ensures it takes the full height of the column */
}
.title-text h4 {
  margin: 2.25rem 0;
  margin-bottom: 0 !important;

}

.title-text h3{

}
.title-text p {
  font-size: 1.2rem;
  font-weight: bold;
}

/* Banner */
.banner {
  width: 100%;
  height: 300px; /* Adjust as needed */
  background-image: url('/img/img/header.jpg');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

/* Image Grid */
.image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.25rem;
  padding: 0.25rem;
}
.grid-item {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 200px; /* Adjust height as needed */
}

.uppercase {
  text-transform: uppercase;
}

</style>
